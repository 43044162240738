"use strict";
import * as wishlistbundle from "./wishlist-package";
import {findAllIn, find, findIn, addClass, removeClass, findAll, on} from "@elements/dom-utils";
import {onFind } from "@elements/init-modules-in-scope";

let wishlist = null;
if (window['_wishlist']) {
    wishlist = wishlistbundle.createWishlist({
        ...window['_wishlist'],
        notificationOptions: {
            $container: find('.js-wishlist__notification'),
        }
    })
}

export function init() {
    const addedWishlistHandler = (evt, params) => {
        onFind('.js-wishlist__sum', function(baseElement){
            addClass('wishlist-animation', baseElement);
            setTimeout(function(){
                removeClass('wishlist-animation', baseElement);
            }, 1000);
        });
    };


    on('added.wishlist', (evt) => addedWishlistHandler('params', evt.detail) , document);


    if(wishlist){
        wishlist.init();
    }

    const renderWishlistHandler = (evt, params) => {
        console.log("render wishlist");
        if(params.list === "wishlist-list"){
            // update counter
            let wishlistItemCounter = findAll('.js-wishlist__items-count');
            if(params.activeIds && wishlistItemCounter){
                wishlistItemCounter.map(function(counter) {
                    const count = params.counter || params.activeIds.length;
                    if(count <= 0) {
                        counter.innerHTML= count;
                        counter.setAttribute('hidden', 'hidden');
                    }else {
                        counter.innerHTML= count;
                        counter.removeAttribute('hidden');
                    }
                })
            }
        }
    };

    on('renderd.wishlist', (evt) => renderWishlistHandler('params', evt.detail) , document);
}