import axios from "axios";
import {on, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";


export function init () {
    onFind(".js-ajax-form-add-to-cart", function (form) {
        let button = findIn(".js-count-cart-items", form);
        let url = button.dataset.cartItemsResponseUrl;
        on('success.ajax-form', (evt) => {
            axios.get(url)
                .then(function (response) {
                    return response;
                })
                .then(function(data) {
                    let count = data;
                    onFind(".js-shop-cart-counter", function (element) {
                        element.innerHTML = count.data.html;
                    });
                })
                .catch(function (error) {
                    // Fehler verarbeiten
                    console.log(error);
                })
                .finally(function () {
                    // Wird immer ausgeführt
                });
        }, form);
    });
}